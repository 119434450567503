<template>
    <div>
        <v-card  outlined class="notas-nao-pagas-card">
            <v-card-title >
                <span class="mr-2">Notas Fiscais Não Pagas</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-chip-group mandatory active-class="primary--text" @change="onDataChanged" class="mb-2">
                    <v-chip v-for="data in datas" :key="data" :value="data" small>
                        {{ convertDate(data) }}
                    </v-chip>
                </v-chip-group>

                <span class="ml-4 font-weight-medium">{{totalCount}} notas fiscais não pagas</span>
                <div v-if="notasFicais" class="d-flex align-center mb-4">
                    <v-spacer></v-spacer>
                    <v-pagination v-model="page" total-visible="7"
                                  :length="Math.ceil(totalCount / offset)"
                                  @input="pageChanged"></v-pagination>
                    <v-spacer></v-spacer>
                </div>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Número
                            </th>
                            <th class="text-left">
                                Data de Emissão
                            </th>
                            <th class="text-left">
                                Lote
                            </th>
                            <th class="text-left">
                                Valor
                            </th>
                            <th class="text-left">
                                Discriminação
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="nf in notasFicais" :key="nf.id" >
                            <td>
                                <router-link :to="{ name: 'nota_fiscal_view', params: { id: parseInt(nf.id) }}">{{ nf.numero }}</router-link>
                            </td>
                            <td>{{ nf.dataEmissao | moment("DD MMM YYYY") }}</td>
                            <td>
                                <router-link :to="{ name: 'servicos', query: { lote: parseInt(nf.servico.loteServico.id), page:1 }}">{{ nf.servico.loteServico.codigo }}</router-link></td>
                            <td>{{ nf.valorServicos | formatNumber}}</td>
                            <td class="font-weight-regular">{{nf.discriminacao}}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <div v-if="notasFicais" class="d-flex align-center my-4">
                    <v-spacer></v-spacer>
                    <v-pagination v-model="page" total-visible="7"
                                  :length="Math.ceil(totalCount / offset)"
                                  @input="pageChanged"></v-pagination>
                    <v-spacer></v-spacer>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "NotasFiscaisNaoPagasCard",
    data(){
        return{
            dataAtual: null,
            datas: [],
            offset: 10,
            page: 1,
            totalCount: 0,
            notasFicais: [],
            currentFilter: {},
            loading: true,
        }
    },
    mounted() {
        this.getDatasNotasNaoPagas();
    },
    methods:{
        convertDate(value){
            return this.$moment(value).format('MMM YY')
        },
        pageChanged(value){
            this.page = value
            this.getNotasFiscaisNaoPagasPorPeriodo(this.dataAtual, this.page)
        },
        getDatasNotasNaoPagas(){
            this.$apollo.query({
                query : gql`query {
                    datasNotasFiscaisNaoPagas
                }`,
            }).then((result) => {
                this.datas = result.data.datasNotasFiscaisNaoPagas;
            })
        },
        getNotasFiscaisNaoPagasPorPeriodo(data, page){
            this.loading = true;

            let filter = {
                "searchValue": null,
                "itens": [
                    {
                        "name": "pagas",
                        "booleanData": {
                            "status": false
                        }
                    },
                    {
                        "name": "ordenar",
                        "sortData": {
                            "ascending": false,
                            "fieldName": "numero"
                        }
                    },
                    {
                        "name": "data-emissao",
                        "dateData": {
                            "after": this.$moment(data + "-01"),
                            "before": this.$moment(data + "-" + this.$moment(data, 'YYYY-MM').daysInMonth()),
                        }
                    }
                ],
                "searchCriteria": null
            }

            this.$apollo.query({
                query: gql`query($filter: SearchFilterInput!, $page: Int, $offset: Int) {
                        searchNotasFiscais(filter: $filter, page: $page, offset: $offset){
                            totalCount
                            notasFiscais:itens{
                                id
                                numero
                                dataEmissao
                                valorServicos
                                discriminacao
                                servico {
                                    loteServico {
                                        id
                                        codigo
                                    }
                                }
                            }
                        }
                    }`,
                variables: {
                    filter,
                    page: page - 1,
                    offset: this.offset
                },
            }).then((result) => {
                this.notasFicais = result.data.searchNotasFiscais.notasFiscais;
                this.totalCount = result.data.searchNotasFiscais.totalCount;
                this.loading = false;
            });
        },
        onDataChanged(data){
            this.dataAtual = data;
            this.page = 1;
            this.getNotasFiscaisNaoPagasPorPeriodo(this.dataAtual, this.page)
        }
    }
}
</script>

<style>
.notas-nao-pagas-card .v-pagination__item,
.notas-nao-pagas-card .v-pagination__navigation{
    font-size: 13px;
    height: 26px;
    min-width: 26px;
}
</style>
<style scoped>
.notas-nao-pagas-card table tr{
    white-space: nowrap;
}
.notas-nao-pagas-card table tr td{
    font-size: 13px !important;
}


</style>