<template>
    <page title="Financeiro">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div v-if="$parent.$parent.usuario.admin">
            <v-btn @click="iniciaTarefa1">Tarefa 1</v-btn>-->
            <v-btn @click="iniciaTarefa2">Tarefa 2</v-btn>
        </div>
<!--        <div slot="menu" >
            <v-menu offset-y v-if="$parent.$parent.usuario.admin" >
                <template v-slot:activator="{ on }">
                    <v-btn outlined style="border: solid thin rgba(0, 0, 0, 0.12)" v-on="on">
                        <v-icon left>mdi-cog</v-icon>
                        <span>Ações</span>
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :disabled="uploadingOfx" @click="onUploadArquivoButtonClicked">
                        <v-list-item-title>Enviar Arquivo OFX</v-list-item-title>
                    </v-list-item>
                    <v-list-item :disabled="uploadingOfx" @click="baixarArquivoOfx">
                        <v-list-item-title>Baixar Arquivo OFX</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn class="ml-2" text outlined :to="{name:'remessas'}">Remessas</v-btn>
            <v-btn class="ml-2" text outlined :to="{name:'retornos'}">Retornos</v-btn>
            <input v-show="false" ref="inputFileUpload" type="file"
                   @input="uploadOfx" >
        </div>-->

        <v-row>
            <v-col cols="12" md="3" class="pt-0">
                <v-row>
                    <v-col>
                        <v-card class="overflow-auto">
                            <div class="green lighten-1 pa-2">
                                <line-chart :height="200" :chart-data="fechamentosGraph" :options="chartOptions" />
                            </div>
                            <v-card-title >
                                <span>Histórico de Caixa</span>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <v-list dense class="py-0" v-if="fechamentos.length > 0">
                                    <template v-for="(fechamento, index) in fechamentos">
                                        <v-list-item  :key="index" :to="{name: 'fechamento_view', params:{id:fechamento.id}}">
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-regular">{{fechamento.nome}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="my-0">
                                                <v-list-item-action-text>
                                                    {{fechamento.valorTotalLiquido | formatNumber}}
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
                <v-row v-if="fechamentoAberto">
                    <v-col cols="12" sm="4">
                        <v-card color="red accent-4" dark :to="{name: 'fechamento_view', params:{id:-1}}">
                            <v-card-subtitle class="font-weight-bold">Despesas em aberto</v-card-subtitle>
                            <v-card-text class="text-h5 font-weight-bold text-right pt-sm-0 ">
                                {{fechamentoAberto.valorTotalDespesas | formatNumber}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card color="blue accent-4" dark :to="{name: 'fechamento_view', params:{id:-1}}">
                            <v-card-subtitle class="font-weight-bold">Receitas em aberto</v-card-subtitle>
                            <v-card-text class="headline font-weight-bold text-right pt-sm-0">
                                {{fechamentoAberto.valorTotalReceitas | formatNumber}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-card color="teal" dark :to="{name: 'fechamento_view', params:{id:-1}}">
                            <v-card-subtitle class="font-weight-bold">Total em caixa</v-card-subtitle>
                            <v-card-text class="headline font-weight-bold text-right pt-sm-0">
                                {{fechamentoAberto.valorTotalLiquido | formatNumber}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <servico-sem-cobranca-resumo-card />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <notas-fiscais-nao-pagas-card />
                    </v-col>
                </v-row>

            </v-col>
            <v-col cols="12" md="3"  class="pt-0">
                <v-row>
                    <v-col>
                        <v-card class="mb-6" v-if="detalhesConta" :to="{name:'conta'}" color="amber accent-1" outlined>
                            <v-card-title >
                                <span class="mr-2">Detalhes da Conta</span>
                                <!--                                <v-spacer/>
                                                                <v-btn text color="primary" :to="{name:'conta'}">
                                                                    Mais detalhes
                                                                </v-btn>-->
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-alert type="error" dense color="orange" class="mb-0" v-if="detalhesConta.totalLancamentosSemMovimentacao > 0">
                                            <span class="caption">{{detalhesConta.totalLancamentosSemMovimentacao}} lançamento(s) sem movimentação para serem corrigidos</span>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <v-list dense color="transparent">
                                            <v-list-item class="px-0" style="min-height: unset">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-regular">Saldo</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0" style="min-width: unset">
                                                    <v-list-item-action-text>{{detalhesConta.saldo | formatNumber}}</v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item class="px-0" style="min-height: unset">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-regular">Total Lançamentos Futuros</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0" style="min-width: unset">
                                                    <v-list-item-action-text>{{detalhesConta.totalLancamentosFuturos}}</v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item class="px-0" style="min-height: unset">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-regular">Último Retorno Recebida</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0" style="min-width: unset">
                                                    <v-list-item-action-text>{{detalhesConta.ultimoRetornoRecebido | moment('DD MMM YYYY')}}</v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <!--                            <v-card-actions>
                                                            <v-btn text color="primary" :to="{name:'conta'}">
                                                                Mais detalhes
                                                            </v-btn>
                                                        </v-card-actions>-->
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </page>
</template>

<script>
import page from "@/components/Page";
import LineChart from "@/components/charts/LineChart";
import gql from "graphql-tag";
import ServicoSemCobrancaResumoCard from "@/views/financeiro/fragments/ServicoSemCobrancaResumoCard";
import NotasFiscaisNaoPagasCard from "./fragments/NotasFiscaisNaoPagasCard";

export default {
    name: "View",
    components: {
        NotasFiscaisNaoPagasCard,
        ServicoSemCobrancaResumoCard,
        page,
        LineChart,
    },
    data(){
        return{
            fechamentoAberto: null,
            fechamentos: [],
            fechamentosGraph: {},
            chartOptions: {
                maintainAspectRatio:false,
                responsive: true,
                tooltips: {
                    callbacks: {
                        label:(tooltipItems, data) => {
                            return data.datasets[tooltipItems.datasetIndex].label+': '+this.$options.filters.formatNumber(tooltipItems.yLabel)
                            //return tooltipItems.yLabel + ' : ' + tooltipItems.xLabel + " Files";
                        },
                    }
                },
                scales:{
                    xAxes: [{
                        offset: true,
                        stacked: true,
                        //display: false,
                        ticks: {
                            fontColor: 'white',
                            //display: false,
                        },
                        gridLines: {
                            color: 'rgba(67,160,71,0.4)'
                        }
                    }],
                    yAxes: [{
                        //display: false,
                        ticks: {
                            fontColor: 'white',
                            display: false,
                        },
                        gridLines: {
                            color: 'rgba(67,160,71,0.4)'
                        }
                    }],
                },
                legend:{
                    labels:{
                        fontColor: 'white'
                    }
                },
                /*legend:{
                    display: false,
                },
                scales:{
                    xAxes: [{
                        gridLines: {
                            display:false
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display:true,
                            color: 'rgb(246,246,246)'
                        }
                    }],

                },*/

            },
            detalhesConta: null,
        }
    },
    mounted() {
        document.title = "Financeiro - JF Assessoria Rural"
        this.getFechamentoAbertoInfo();
        this.getFechamentos();
        this.getDetalhesConta();
    },
    methods:{
        getFechamentoAbertoInfo(){
            this.$apollo.query({
                query : gql`query {
                    fechamento(id: -1){
                        valorTotalDespesas
                        valorTotalReceitas
                        valorTotalLiquido
                    }
                }`,
            }).then((result) => {
                this.fechamentoAberto = result.data.fechamento;
            })
        },
        getFechamentos(){
            this.$apollo.query({
                query : gql`query {
                    fechamentos{
                        id
                        nome
                        dataFechamento
                        valorTotalDespesas
                        valorTotalReceitas
                        valorTotalLiquido
                    }
                }`,
            }).then((result) => {
                this.fechamentos = result.data.fechamentos;

                let fechamentosReversed = this._.cloneDeep(this.fechamentos).reverse();
                this.fechamentosGraph = {
                    labels: fechamentosReversed.map(f => {
                        if(!f.dataFechamento){
                            return 'Aberto'
                        }
                        return this.$moment(f.dataFechamento).subtract(1, 'months').format("MMM/YY")
                    }),
                    datasets: [
                        {
                            label: 'Despesas',
                            backgroundColor: 'rgba(255,0,0, 0.4)',
                            //borderColor: 'rgba(255,71,71,0.5)',
                            //borderWidth: 1,
                            type: 'bar',
                            //fill: false,
                            data: fechamentosReversed.map(f => -f.valorTotalDespesas),
                            //lineTension: 0
                        }, {
                            label: 'Receitas',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            //borderColor: 'rgba(255,255,255,0.7)',
                            //borderWidth: 1,
                            type: 'bar',
                            //fill: false,
                            data: fechamentosReversed.map(f => f.valorTotalReceitas),
                            //lineTension: 0
                        }
                    ],

                };
            })

        },
        getDetalhesConta(){
            this.$apollo.query({
                query : gql`query {
                    detalhesConta{
                        saldo
                        totalLancamentosFuturos
                        ultimoRetornoRecebido
                        totalLancamentosSemMovimentacao
                    }
                }`,
            }).then((result) => {
                this.detalhesConta = result.data.detalhesConta;
            })
        },
        iniciaTarefa1(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                    iniciaTarefa1{
                        id
                    }
                }`,
            }).then((result) => {
                console.log("iniciaTarefa1.result", result.data.iniciaTarefa1);
            })
        },
        iniciaTarefa2(){
            this.$apollo.mutate({
                mutation: gql`mutation {
                    iniciaTarefa2{
                        id
                    }
                }`,
            }).then((result) => {
                console.log("iniciaTarefa2.result", result.data.iniciaTarefa1);
            })
        },


    }
}
</script>

<style scoped>

</style>