<template>
    <div>
        <v-card  outlined v-if="resumo" class="servicos-card" >
            <v-card-title >
                <span class="mr-2">Serviços à Cobrar</span>
            </v-card-title>
            <v-card-text class="pa-0">
                <v-list dense class="py-0" color="transparent" v-if="resumo.quantidadeFiscalizacoes > 0 || resumo.quantidadeProjetos > 0">
                    <v-list-item :to="{name:'servicos'}">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-regular">{{resumo.quantidadeFiscalizacoes}} fiscalizações</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="my-0">
                            <v-list-item-action-text>
                                {{resumo.valorFiscalizacoes | formatNumber}}
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="">
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-regular">{{resumo.quantidadeProjetos}} projetos</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="my-0">
                            <v-list-item-action-text>
                                {{resumo.valorProjetos | formatNumber}}
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <div v-else>
                    <div class="text-center my-4">
                        <span>Nenhum serviço sem cobrança!</span>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import gql from "graphql-tag";
export default {
    name: "ServicoSemCobrancaResumoCard",
    data(){
        return{
            resumo: null,
        }
    },
    mounted() {
        this.getResumoServicosACobrar();
    },
    methods:{
        getResumoServicosACobrar(){
            this.$apollo.query({
                query : gql`query {
                    servicosSemCobrancaResumo{
                        quantidadeFiscalizacoes
                        valorFiscalizacoes
                        quantidadeProjetos
                        valorProjetos
                    }
                }`,
            }).then((result) => {
                this.resumo = result.data.servicosSemCobrancaResumo;
            })
        }
    }
}
</script>

<style scoped>
    .servicos-card{
        border: thin solid rgb(255 141 0 / 45%);
    }
</style>